import React from 'react';
import { cloneDeep } from 'lodash';

// mui
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import { Button, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';

// utils
import selectInputsData from '../../../utils/functions/selectInputsData';

const Requirements = ({ data, setData, selected, currentForm }) => {
    
    const currentSubSection = currentForm.sub_sections.find(ss => ss.sectionKey === selected)
    const renderRequirements = currentSubSection.requirements

    const [newKey, setNewKey] = React.useState(false)

    const handleSelection = (e, key, type) => {
        const updateKey = cloneDeep(newKey)
        updateKey[key] = e.target.value
        if (type) {
            updateKey.type = type
        }
        setNewKey(updateKey)
    }

    const selectOptions = selectInputsData.getFullSelectOptions(data)

    const requiredTarget = selectInputsData.findObject(data, newKey.key)

    const saveRequirement = () => {
        const newData = cloneDeep(data)
        const newForm = cloneDeep(currentForm)
        const newSubSection = cloneDeep(currentSubSection)

        if (!newSubSection?.requirements) {
            newSubSection.requirements = []
        }

        newSubSection.requirements.push(newKey)        
        
        newForm.sub_sections = newForm.sub_sections.map(ss => {
            return ss.sectionKey === newSubSection.sectionKey ? newSubSection : ss
        })

        newData.forms.sections = newData.forms.sections.map(form => {
            return form.type === newForm.type ? newForm : form
        })
        
        setData(newData)
        setNewKey(false)
    }

    const deleteRequirement = (index) => {
        const newData = cloneDeep(data)
        const newForm = cloneDeep(currentForm)
        const newSubSection = cloneDeep(currentSubSection)

        newSubSection.requirements.splice(index, 1)
        
        newForm.sub_sections = newForm.sub_sections.map(ss => {
            return ss.sectionKey === newSubSection.sectionKey ? newSubSection : ss
        })

        newData.forms.sections = newData.forms.sections.map(form => {
            return form.type === newForm.type ? newForm : form
        })
        setData(newData)
        setNewKey(false)
    }

    return (
        <>
            <Typography variant="h5"  style={{float: "Left"}}>Requirements</Typography>
            <br /><br />
            
            <Button onClick={() => setNewKey({key: false, value: false})}>Click Here to Create New Requirement</Button>

            <FormHelperText id="component-helper-text">
                Requirement is a tool that allows this group to only display when certain selections are made. For example, if client selects &quot;pick up&quot; option then the group handling the address of where to pick-up would need to be displayed. Otherwise, the group would be hidden.
            </FormHelperText>
            
            <ul>
                {renderRequirements?.map((r, i) => {
                    const optRef = selectOptions?.find(item => item.key === r.key)
                    const displayItem = r.type === "specified" || !r.type ? r.value : data.references[r.type]?.find(item => item.LIMS_ID === r.value)?.DISPLAYNAME

                    return (
                        <li key={r.key+i}>
                            <b>Form Item: </b>{optRef.text} (key: {r.key}),<b> Value: </b>&quot;{displayItem}&quot;
                            <Button onClick={() => deleteRequirement(i)}>Delete</Button>
                        </li>
                    )
                })}
            </ul>

            {newKey ? 
                <div>
                    <Typography variant="h5"  style={{float: "Left"}}>New Key Options</Typography>

                    <Button 
                        onClick={() => {
                            setNewKey(false)
                        }}
                    >
                        Cancel
                    </Button>
                    
                    <Button disabled={!newKey.key || !newKey.value} onClick={saveRequirement}>Save</Button>

                    <FormControl fullWidth>                    
                        <InputLabel id="demo-simple-select-label">Available Keys</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            style={{width: "100%"}}
                            id="demo-simple-select"
                            value={newKey.key || false}
                            label="Available Keys"
                            onChange={(e) => handleSelection(e, 'key')}
                        >   
                            {selectOptions.map((option, i) => {
                                return <MenuItem key={option.key + i + 'selectOpt'} value={option.key}>{option.text}</MenuItem>
                            })}
                        </Select>
                        <FormHelperText id="component-helper-text">
                            Choose which item will trigger this group to become viewable.
                        </FormHelperText>
                    </FormControl>

                    {newKey.key ? 
                        <FormControl fullWidth>                    
                        <InputLabel id="demo-simple-select-label">Available Options</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                style={{width: "100%"}}
                                id="demo-simple-select"
                                defaultValue={""}
                                value={newKey.value || ""}
                                label="Available Options"
                                onChange={(e) => handleSelection(e, 'value', requiredTarget.selection.source)}
                            >   
                                {requiredTarget?.selection?.value?.map((row, i) => {
                                    const displayItem = requiredTarget.selection.source === "specified" ? row : data.references[requiredTarget.selection.source]?.find(item => item.LIMS_ID === row)?.DISPLAYNAME
                                    return <MenuItem key={displayItem + i + 'selectOpt'} value={row}>{displayItem}</MenuItem>
                                })}
                            </Select>
                            <FormHelperText id="component-helper-text">
                                Which entry will trigger view? You can add more rules if there are multiples from this selection that should trigger viewability.
                            </FormHelperText>
                            <Alert severity="warning">BE AWARE: if you change the text or options of the item selected above it will not automatically update the choices below. If you change the text of these options then you must come back here and re-select the desired choices.</Alert>
                        </FormControl>
                    : null}
                                    
                </div>                
            : null}
            


        </>                              
    );

}

export default Requirements;
