import * as React from 'react';
import cloneDeep from 'lodash/cloneDeep';

// mui
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Link } from '@mui/material';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

// utils
import FormAPI from '../../utils/API/api-form';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Signature = ({ data, open, setOpen, userInput, setUserData, samplingMode }) => {
    
  const [name, setName] = React.useState("")
  const [terms, setTerms] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState(false)

  const finalSubmitRelinquish = async () => {
    const signature = {
      name: name?.trim(),
      dateTime: new Date(),
      type: 'relinquish',
      staff: 'client'
    }

    setLoading(true)
    try {
      const sendInput = cloneDeep(userInput)

      sendInput.final = true
      if (sendInput.signatures) {
          sendInput.signatures.push(signature)
      } else {
          sendInput.signatures = [signature]
      }
      
      sendInput.status = samplingMode ? 'submitted' : "client-signed"
      
      let prefix = false
      if (samplingMode) {
        const samplingForm = data.activeSamplingEvent || {}
        prefix = samplingForm.submissionPrefix || prefix
      }

      const res = await FormAPI.saveClientSubmission({data: {
          userJSON: sendInput, prefix: prefix
      }})
              
      if (res.status === 200) {            
          setError(false)            
          setUserData(res.data)
          window.history.pushState('page2', 'Title', `/${res.data.serviceLine}/${res.data.submissionID}/`)
          setLoading(false)
          setName('')
          setOpen(false)

          // send email
          const res2 = await FormAPI.submitClientDataEmail({data: res.data, type: samplingMode ? 'samplingEvent' : 'samples'})
          // CHECK THAT THE EMAIL PASSED
          if (res2.status === 200) {            
              setError(false)                   
          } else {            
              setError("emailErr")
          }

      } else {
          setLoading(false)
          setError("saveErr")
      }
    } catch (err) {
      console.log(err)
      setLoading(false)
      setError("saveErr")
    }
    
}

  const handleCancel = () => {
    setOpen(false);
    setTerms(false)
    setName("")

  };

  return (
    <div>
      <Dialog
        open={open}
        style={{maxWidth: "100vw"}}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCancel}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle style={{ textAlign: 'center' }}>{loading ? "Saving Submission" : "Agree to Terms of Service & Sign Document"}</DialogTitle>
        <DialogContent>
          {loading ? 
            <LinearProgress />
          : 
            <>
            {error ? "We apologize for the inconvenience. There was an error submitting your request. Please click below to try again. If the error persists please contact the laboratory right away for further assistance.."
            : 
            <Stack spacing={3}>
            <Item sx={{bgcolor: "#90EE90"}} >
              <Checkbox
                checked={terms}
                onClick={() => {
                  setTerms(!terms)
                  setName('')
                }}
                sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } , float: "left"}}                    
              />
              <Typography variant="span">Check here to indicate that you have read and agree to the </Typography> 
              <Link href="/pdf/cfl-ecoc-terms-and-conditions.pdf" target="_blank" >
                Columbia Laboratories Sample Submission Terms of Service<PictureAsPdfIcon />
              </Link>. 
            </Item>


            <Item sx={{bgcolor: terms ? "#90EE90" : "#EEEEEE"}} >
              <Typography variant="span">Signing this document, by filling your name and clicking &ldquo;Sign Document&ldquo; below, will save and submit your entries to the laboratory.</Typography>
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <TextField disabled={!terms} onChange={(e) => setName(e.target.value)} value={name} id="standard-basic" placeholder="Sign Full Name" variant="standard" style={{width: "100%"}} inputProps={{min: 0, style: { textAlign: 'center', fontSize: "1.5em" }}}/>
                <BorderColorIcon sx={{ color: 'action.active', ml: 1, my: 0.5 }} />
              </Box>
              
            </Item>

            <Item sx={{bgcolor: name ? "#90EE90" : "#EEEEEE"}} >
              <Typography variant="span">After clicking &ldquo;Sign Document&ldquo; below an email will be sent to you ({userInput.clientEmail}) and the laboratory. To make any further revisions after this step please contact the laboratory prior to sample relinquishment.</Typography> 

            </Item>

          </Stack>
            }

            </>
          
          }
         
          

        </DialogContent>
        
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
            {error ? 
              <Button onClick={finalSubmitRelinquish}>Try Again</Button>
            :
              <Button disabled={name?.trim() === ""} onClick={finalSubmitRelinquish}>Sign Document</Button>
            }          
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Signature;
