import React, { useEffect } from 'react';
import cloneDeep from 'lodash/cloneDeep';

// components
import Form from './Form/Form';
import Review from './Review/Review';
import Header from './Header.js'
import Footer from './Footer';

// mui
import Grid from '@mui/material/Grid';
import ErrorIcon from '@mui/icons-material/Error';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Snackbar from '@mui/material/Snackbar';

// models
import ModeModel from '../models/ModeModel.js';

// utils
import APIQueries from '../utils/API/api-queries';
import iterateRequirements from '../utils/functions/iterateRequirements';
import modeDetector from '../utils/functions/modeDetector.js';

// styles
import '../utils/styles/styleSheet.css'
import dependencyCheck from '../utils/functions/dependencyCheck';
import individualValCheck from '../utils/functions/individualValCheck';
import samplingEventTools from '../utils/functions/samplingEventMode.js';

const Canvas = () => {
    
    const [data, setData] = React.useState({})

    const [userInput, setUserInput] = React.useState({samples: []})
    const [page, setPage] = React.useState(0)
    const [mode, setMode] = React.useState("form")
    const [entryMode, setEntryMode] = React.useState(false)
    const [checkRequirements, setCheckRequirements] = React.useState(false)
    const [mirrored, setMirrored] = React.useState({})
    const [pageSamples, setPageSamples] = React.useState(0)
    const [submissionErrors, setSubmissionErrors] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    const [samplingMode, setSamplingMode] = React.useState(false)
    const [pageSnackbar, setPageSnackbar] = React.useState(false)

    // pagination
    const maxPages = Math.ceil(userInput?.samples?.length / 10) || 1
    
    const handleNewSample = () => {
        const newInput = cloneDeep(userInput)
    
        const currLast = newInput.samples?.length > 0 ? (newInput.samples[newInput.samples.length -1]).id : -1
    
        
        if (currLast > -1) {
            newInput.samples.push({id: currLast + 1})     
        } else {
            // create array
            newInput.samples = [{id: 0}]
        }
                
        newInput.samples.sort((a, b) => (a.id > b.id ? 1 : -1))
        setUserInput(newInput)
    }

    const handleRemoveSample = (sampleID) => {
        let newInput = cloneDeep(userInput)       

        // remove sample
        newInput.samples = newInput.samples.filter(s => {            
            return s.id !== sampleID
        })

        // re-ID samples
        newInput.samples.forEach((s, i) => {
            s.id = i
        })

        // set testing on sampling mode
        if (data.activeSamplingEvent) {
            newInput = data.activeSamplingEvent.setTesting(newInput, data)
        }
        
        setUserInput(newInput)
    }

    const initialMirrors = (source) => {
        const formClient = source.forms.sections.find(f => f.type === 'client')

        const returnObj = {}

        formClient.sub_sections.forEach(ss => {
            if (ss.useAsAbove) {
                returnObj[ss.sectionKey] = true
                ss.inputs.forEach(input => {
                    returnObj[input.input_key] = true 
                })
            }
        })

        return returnObj
    }

    const loadData = async (submissionID, serviceLine, materialsOrder, samplingEvent) => {

        setLoading(true)
        // at some point materials ordering will have form data to go with it so the queries below will need to be changed, for now they're just front-end        
        setEntryMode(materialsOrder || 'samples')

        try {
            // data requried for form to render
            const fullData = await APIQueries.getFullData('cfl', serviceLine, true, true, submissionID, ["samplingEvent"])
            
            // set initial data set here
            const formattedData = {
                forms: fullData.form,
                testing: fullData.testing,
                references: fullData.references,
                samplingEvent: fullData.sampling.samplingEvent,
            }

            // if this is a material order, set mode here
            if (fullData.clientSubmissionData?.materialOrders.length) {
                setEntryMode('materialOrders')
            }
            
            // set any possible mode options here
            const samplingEventModes = fullData.form?.sections?.filter(sec => sec.samplingEventKey).map(sec => {
                return new ModeModel(sec)
            })

            if (samplingEventModes?.length > 0) {
                formattedData.samplingEventModes = samplingEventModes
            }

            if (samplingEvent) {
                formattedData.activeSamplingEvent = samplingEventModes.find(mode => mode.urlParam === samplingEvent)
                setSamplingMode(formattedData.activeSamplingEvent.modeName)
            }

            // confirm all given form related data into state
            setData(formattedData)

            // set initial "set as above" but only on fresh forms
            if (!submissionID) {
                setMirrored(initialMirrors(formattedData))
            }           

            let loadUserData = {
                submissionType: materialsOrder || 'samples'
            }

            if (submissionID) {
                // has data
                loadUserData = fullData.clientSubmissionData
                loadUserData.samples = loadUserData.samples.sort((a, b) => a.id - b.id)

            } else if (loadUserData.submissionType !== 'samples') {
                // fresh material order
                loadUserData.serviceLine = serviceLine
                loadUserData.materialOrders = [{id: 0, materialKey: materialsOrder}]
                
            } else {                
                // fresh samples order
                loadUserData.serviceLine = serviceLine
                loadUserData.samples = [{id: 0}]

                if (serviceLine === "cannabis-sampling") {
                    loadUserData.samplesDelivery = "Sampling Event Request"
                }
            
            }

            if (loadUserData.status?.indexOf('form') > -1 || !loadUserData.status) {
                setMode('form')
                setLoading(false)
            } else {
                setMode('review')
                setLoading(false)
            }

            if (loadUserData.samples) {
                loadUserData.samples = loadUserData.samples.sort((a, b) => a.id - b.id)
            }

            if (formattedData.activeSamplingEvent) {

                loadUserData.submissionType = `samplingEvent,${formattedData.activeSamplingEvent.urlParam}`

                // set preset inputs
                loadUserData = formattedData.activeSamplingEvent.setPresetInputs(loadUserData)
                
                // set preset testing
                loadUserData = formattedData.activeSamplingEvent.setTesting(loadUserData, formattedData)

            }

            setUserInput(loadUserData)

        } catch (err) {
            console.log(err)
            setMode("formError")
            setLoading(false)
        }
    }

    useEffect(() => {
        // get form data when loading page from fresh.
        // use this for API calls...
        const currentURL = window.location.href
        const urlArr = currentURL.split('/')        
        const serviceLine = urlArr[3].split('?')[0]
        const materialOrder = urlArr[3].split('materials=')[1]
        const samplingEvent = urlArr[3].split('sampling-event=')[1]
        const userSubmissionID = urlArr[4] ? decodeURIComponent(urlArr[4]) : false

        loadData(userSubmissionID, serviceLine, materialOrder || 'samples', samplingEvent)
        
    }, [])

    const updateSamplesForm = async (serviceLine) => {

        setLoading(true)

        try {
            // data requried for form to render
            const fullData = await APIQueries.getFullData('cfl', serviceLine, true, true, false, ["samplingEvent"])
            
            const formattedData = {
                forms: fullData.form,
                testing: fullData.testing,
                references: fullData.references,
                samplingEvent: fullData.sampling?.samplingEvent,
            }

            const newInput = cloneDeep(userInput)
            // get rid of any variant here
            const newClient = fullData.form.sections.find(s => s.type === 'client')
            const masterInputs = [...newClient.sub_sections.map(ss => ss.inputs).flat()].map(k => k.input_key)       
            const currentInputs = Object.keys(userInput).filter(i => i !== 'samples')
            currentInputs.forEach(k => {
                if (masterInputs.indexOf(k) < 0) {
                    delete newInput[k]
                }
            })

            newInput.samples = [{id: 0}]
            newInput.serviceLine = serviceLine
            
            if (newInput.submissionType?.includes('samplingEvent')) {
                const samplingEvent = newInput.submissionType.split(',')[1]
                setSamplingMode(samplingEvent)
            } else {
                newInput.submissionType = 'samples'
            }

            setData(formattedData)
            setUserInput(newInput)
            setLoading(false)
        } catch (err) {
            console.log(err)
            setMode("formError")
            setLoading(false)
        }

    }

    useEffect(() => {
        // update form when detecting a change from an already loaded page

        // this is for cannabis-sampling but could get into other sampling events
        if (!!Object.keys(data).length && !userInput.template && mode === 'form' && userInput.serviceLine.includes('cannabis')) {
            
            const currentURL = window.location.href
            const urlArr = currentURL.split('/')
            updateSamplesForm(urlArr[3])        
        } else {
            // just in case something's loading, turn it off
            setLoading(false)
        }
        
    }, [window.location.pathname])

    useEffect(() => {
        if (mode !== 'form') {
            return
        }
       // incoming template. This was isolated because of async nature of state updating
       if (userInput.template) {
        const newInput = cloneDeep(userInput)
        delete newInput.template

        if (newInput.submissionType === 'samples' || newInput.submissionType.includes('samplingEvent')) {
            setEntryMode('samples')    
        } else {
            setEntryMode(newInput.submissionType)
        }
            
        setUserInput(newInput)
        setLoading(false)
       }

       // detect mode shifting here, for sampling event primarily
       // only do it if there's options
        if (data.samplingEventModes && !loading && userInput) {
            const updatedModeParams = modeDetector(data.samplingEventModes || [], userInput || {}, samplingMode)

            if (updatedModeParams && !samplingMode) {
                // set state
                setSamplingMode(updatedModeParams.modeName)

                // set page URL
                window.history.pushState('page2', 'Title', `/${userInput.serviceLine}?sampling-event=${updatedModeParams.urlParam}${userInput.submissionID ? '/' + userInput.submissionID + '/' : ''}`)  
                
                // set testing here!!
                // only put the field testing on the first sample
                let newUserInput = cloneDeep(userInput)
                newUserInput = updatedModeParams.setTesting(userInput, data)
                newUserInput.submissionType = `samplingEvent,${updatedModeParams.urlParam}`
                setUserInput(newUserInput)

                const newData = cloneDeep(data)
                newData.activeSamplingEvent = updatedModeParams
                setData(newData)
                setPageSnackbar(`Form set for ${updatedModeParams.modeName} Sampling Event!`)

            } else if (!updatedModeParams && samplingMode) {
                window.history.pushState('page2', 'Title', `/${userInput.serviceLine}/${userInput.submissionID ? userInput.submissionID + '/' : ''}`)
                setSamplingMode(false)
                // remove the field testing here
                const newUserInput = cloneDeep(userInput)
                newUserInput.samples[0].activeTests = data.activeSamplingEvent.removeTesting(newUserInput.samples[0].activeTests)
                newUserInput.submissionType = 'samples'
                setUserInput(newUserInput)

                
                const newData = cloneDeep(data)
                newData.activeSamplingEvent = false
                setData(newData)
                setPageSnackbar(`Form reverted to standard sample submission!`)
            } else {
                // nothing fundemental changed but maybe check that the first sample has the required testing here in case they remove the sample or something
                
            }
        }

    }, [userInput, mode])

    useEffect(() => {
        if (userInput.serviceLine === 'environmental') {
            const newInput = samplingEventTools.updateInputs(userInput, data, 'samples')
            newInput.submissionType = samplingMode ? `samplingEvent,${data.activeSamplingEvent.urlParam}` : entryMode
            setUserInput(newInput)
        }
     }, [samplingMode])

    const handleSubmit = () => {

        const clientData = cloneDeep(data.forms.sections.find(f => f.type === 'client'))

        // remove requirements depending on ENTRY MODE
        // could piggy-back more here since we're filtering down
        // could possibly do this filtering on the form query itself
        clientData.sub_sections.forEach(ss => {
            ss.inputs = samplingEventTools.filteredInputs(ss.inputs, data.activeSamplingEvent || false, entryMode)
        })

        const referenceInput = cloneDeep(userInput)
        const newMirrored = cloneDeep(mirrored)
                        
        // client-level only for now, no "sections" in samples do "reuqirements" yet... so someting to not forget about!!    
        const requirementSubsections = clientData?.sub_sections?.filter(ss => ss.requirements?.length > 0)
        
        requirementSubsections.forEach(ss => {
            let removeEntries = true
            ss.requirements.forEach(req => {
                if (dependencyCheck({key: req.key, value: [req.value]}, userInput)) {
                    removeEntries = false
                }
            })

            // remove
            if (removeEntries) {
                // remove inputs
                ss.inputs.forEach(input => {
                    delete referenceInput[input.input_key];
                })

                // remove mirror, preventing re-adding these down the path
                delete newMirrored[ss.sectionKey]
            }
        })

        // below, only works for client-level "use as above"      
        Object.entries(newMirrored).forEach(([key]) => {            
            const mirroredSection = clientData.sub_sections.find(ss => ss.sectionKey === key)
            mirroredSection?.inputs?.forEach(input => {
                if (input.mirrorKey && referenceInput[input.mirrorKey] && newMirrored[key]) {             
                    referenceInput[input.input_key] = referenceInput[input.mirrorKey]
                }
            })
        })
        

        setUserInput(referenceInput)     
        setMirrored(newMirrored)       

            // check for any misisng entries (required)
            
            let missingEntries = []
            // client
            const checkClientRequired = iterateRequirements(referenceInput, clientData, newMirrored)
            missingEntries = [...missingEntries, ...checkClientRequired]

            // validations
            clientData.sub_sections.forEach(ss => {
                ss.inputs.filter(input => input.validation).forEach(input => {
                    if (referenceInput[input.input_key] && !individualValCheck(input.validation.type, referenceInput[input.input_key])) {
                        missingEntries.push(`Submission for ${input.label} must be of type ${input.validation.label}`)
                    }
                })
            })

            if (entryMode === 'samples') {
                // samples
                const sampleData = data.forms.sections.find(f => f.type === 'sample') 
                sampleData.sub_sections.forEach(ss => {
                    ss.inputs = samplingEventTools.filteredInputs(ss.inputs, data.activeSamplingEvent || false, entryMode)                
                })

                referenceInput?.samples.forEach((sample, i) => {
                    
                    // must have testing selected
                    if (!sample.activeTests || sample.activeTests?.length < 1) {
                        missingEntries.push(`Sample ${i + 1} must have testing selected!`)
                    }

                    // all bottles must have lotNumber (but only if the service line demands it)
                    if (data.forms?.bottlesMenu && !samplingMode) {
                        sample.activeBottles?.forEach(b => {
                            if (!b.lotNumber || b.lotNumber?.length < 1) {
                                missingEntries.push(`Sample ${i + 1}, Bottle ${b.BOTTLE_NAME} must have Lot Number!`)
                            }
                        })
                    }

                    // must pass sample check
                    const sampleRequired = iterateRequirements(referenceInput, sampleData, i)
                    missingEntries = [...missingEntries, ...sampleRequired]

                    // sample validations
                    sampleData.sub_sections.forEach(ss => {
                        // dependency
                        ss.inputs?.forEach(input => {
                            if (sample[input.input_key] && !individualValCheck(input.validation?.type, sample[input.input_key])) {
                                missingEntries.push(`Sample ${i + 1} submission for ${input.label} must be of type ${input.validation.label}`)
                            }
                        })
                    })
                        
                })
            } else {
                // strictly bottles here for now
                if (!referenceInput.materialOrders[0]?.activeTests?.length || referenceInput.materialOrders[0]?.activeTests?.length < 1) {
                    // error
                    missingEntries.push(`Must have testing selected!`)
                }
                if (!referenceInput.materialOrders[0]?.activeBottles?.length || referenceInput.materialOrders[0]?.activeBottles?.length < 1) {
                    // error
                    missingEntries.push(`Must have bottles on submission!`)
                }
                
            }



            if (missingEntries.length > 0) {
                setCheckRequirements(true)
                setSubmissionErrors(missingEntries)
            } else {
                setCheckRequirements(false)
                setSubmissionErrors([])
                window.scrollTo(0, 0)
                setMode("review")
            }
    }

    const handleCloseSnackbar = () => {
        setPageSnackbar(false)
    }

    return (
        
        <Grid container spacing={1} id="nav-home" >
        
            <Snackbar
                open={!!pageSnackbar}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                message={pageSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            />

        {/* Header */}
        <Grid item xs={12}   >
            <Header data={data} entryMode={entryMode} loading={loading || !data.forms} />
        </Grid>        

        {/* VARIOUS MODES */}
            {/* Form Mode */}
            {data.forms && mode === "form" && !loading ? 
                <Form 
                    userInput={userInput} data={data} setUserInput={setUserInput} page={page} setPage={setPage} checkRequirements={checkRequirements}
                    handleRemoveSample={handleRemoveSample} handleSubmit={handleSubmit} handleNewSample={handleNewSample} 
                    disabled={mirrored} setDisabled={setMirrored}
                    pageSamples={pageSamples} setPageSamples={setPageSamples} maxPages={maxPages}
                    submissionErrors={submissionErrors} entryMode={entryMode}
                    globalLoading={loading} samplingMode={samplingMode}
                />
            : null}

            {/* Review Mode */}
            {data.forms && mode === "review" ? 
                <Review 
                    samplingMode={samplingMode} userInput={userInput} setUserInput={setUserInput} data={data} setMode={setMode} mirrored={mirrored} entryMode={entryMode} globalLoading={loading} setGlobalLoading={setLoading}
                />
            : null}

            {/* Error Mode */}
            {mode === "formError" ? 
                    
                
                <Grid container xs={12}>
                    <Grid item xs={0} md={3}>                
                    </Grid>

                    <Grid item xs={12} md={6}>                
                        <Paper elevation={2} className="formCanvas"  >
                            <Grid item xs={12} md={12} className="formHeaderContainer"><ErrorIcon className="formHeaderIcon"/><Typography component='h6' variant='h6'>Something Went Wrong! Failed to Load Your Submission!</Typography></Grid>       
                            <Grid item xs={12} md={12} className="formHeaderContainer"><Typography component='p' variant='p'>Please check the address typed into the address bar and try again. If the issue persists please contact the laboratory.</Typography></Grid>        
                        </Paper>
                    </Grid>   

                    <Grid item xs={0} md={3}>                
                    </Grid>
                </Grid>
                
                    
            : null}


        {/* Footer */}
        <Grid item xs={12}    >
            <Footer setPage={setPage} pageRef={userInput.samples ? userInput.samples?.length + 1 : 1} />
        </Grid>   



        </Grid>
        
    )

}

export default Canvas;
